import EmergencyCallDataParser from '@/modules/api/csp/parsers/emergency-call-data.parser'
import heldXmlParser from '@/modules/api/csp/parsers/held/held-xml.parser'
export default function heldParser (session) {
  return {

    emergencyCallDataParser: null,

    init () {
      this.emergencyCallDataParser = new EmergencyCallDataParser().parseGeoLocation(session?._request?.headers?.Geolocation)
    },

    async parse (useRef) {
      this.init()

      return {
        held: (session._request.multipart && !useRef) ? this.heldByVal() : await this.heldByRef()
      }
    },

    async heldByRef () {
      return this.emergencyCallDataParser?.location_url ? await heldXmlParser().parse(this.emergencyCallDataParser?.location_url) : null
    },

    heldByVal () {
      return heldXmlParser().parseHeld(session._request.multipart['application/pidf+xml']?.[0])
    }
  }
}
