import store from '@/store'
import apiService from '@/modules/api/csp'
import localStorageService from '@/util/local-storage-service'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('settings', ['getGlobalSetting'])
  },
  methods: {
    sendDTMF (session, dtmfCode) {
      const dtmfOptions = {
        transportType: this.getGlobalSetting('DTMF_transport_type')?.value?.text || 'RFC2833',
        duration: this.getGlobalSetting('DTMF_durations_mixin')?.value?.text || 500,
        interToneGap: this.getGlobalSetting('DTMF_inter_tone_gap')?.value?.text || 500
      }
      console.log('Check this.getGlobalSetting(\'DTMF_timeout_mixin\')', this.getGlobalSetting('DTMF_timeout_mixin'))
      if (this.getGlobalSetting('DTMF_timeout_mixin')?.value?.active) {
        setTimeout(() => {
          console.log('If delay dtmfOptions', dtmfOptions)
          session.sendDTMF(dtmfCode, dtmfOptions)
        }, this.getGlobalSetting('DTMF_interval_mixin')?.value?.text)
      } else {
        console.log('Else no delay dtmfOptions', dtmfOptions)
        session.sendDTMF(dtmfCode, dtmfOptions)
      }

      const data = {
        call_id: session._request.call_id,
        sip_address: store.getters['settings/sipDefaultAddressGetter'].sip_address,
        sip_message: JSON.stringify({
          from: 'Mixin',
          value: dtmfCode,
          duration: dtmfOptions.duration,
          interToneGap: dtmfOptions.interToneGap,
          transportType: dtmfOptions.transportType
        }),
        method: 'DTMF',
        direction: 'outgoing',
        type: 'json',
        write_time: Date.now(),
        position: localStorageService.getPositionNumber() ?? null
      }

      apiService.callLogs.saveCallLogs(data)
    }
  }
}
